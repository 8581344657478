import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonType,
  Col,
  Row,
} from "syngenta-digital-cropwise-react-ui-kit";
import ForgotPasswordFooter from "./forgotPasswordFooter";
import ConstantKey from "../../core/locale/strings.json";
import MyFieldLogo from "../../assets/icons/myfield-icon.svg";
import accountCircle from "../../assets/icons/account_circle_white.svg";
import forgotNewPasswordImage from "../../assets/images/forgot-newpassword-success.svg";
import "./forgot-password.less";
const NewPasswordSuccess = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const onClickEnterMyField = (): void => {
    navigate("/signin");
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={12} prefixCls="forgot-password-sec">
        <div>
          <div className="forgot-newpassword-success-container">
            <div className="forgot-newpassword-success-content">
              <img
                src={MyFieldLogo}
                className="forgot-password-field-logo"
                alt="myfieldlogo"
              />
              <div className="illustration">
                <img
                  src={forgotNewPasswordImage}
                  className="forgot-password-image-style"
                  alt="emailimage"
                />
              </div>
              <h2 className="forgot-password-desc">
                {translate(ConstantKey.YOUR_NEW_PASSWORD_HAS_BEEN_CREATED)}
              </h2>
              <p className="forgot-password-success-msg">
                {translate(ConstantKey.YOUR_NEW_PASSWORD_CRATED_MSG)}{" "}
              </p>
              <Button
                type={ButtonType.primary}
                onClick={onClickEnterMyField}
                className="email-success-enter-button"
              >
                <img
                  src={accountCircle}
                  className="mf-mr-10"
                  alt="account circle"
                />
                {translate(ConstantKey.ENTER_MYFIELD)}
              </Button>
              <ForgotPasswordFooter />
            </div>
          </div>
        </div>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        prefixCls="forgot-password-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/forgot-password.png)`,
        }}
      ></Col>
    </Row>
  );
};
export default NewPasswordSuccess;
