export const userLogout = "user_logout";

export const exploreWeatherMapClick = "explore_weather_map_click";
export const exploreSprayClockClick = "explore_spray_clock_click";
export const expandedWeatherForecast = "expanded_weather_forecast";
export const titleSyngnetaTechnicalReportsClick =
  "hometab_summary_insight_reports_click";
export const homeTabClick = "home_tab_click";
export const viewHomeScreen = "view_home_screen";
export const mapTabClick = "map_tab_click";
export const plusTabClick = "plus_tab_click";
export const offerTabClick = "offer_tab_click";
export const menuTabClick = "menu_tab_click";
export const radarLayerClick = "radar_layer_click";
export const onCreateFieldClick = "on_create_field_click";
export const onConfirmFieldClick = "on_confirm_field_click";
export const onFarmLocationClick = "on_farm_location_click";
export const onForceUserLocationClick = "on_force_user_location_click";
export const onInsightsButtonClick = "on_insights_button_click";
export const onInsightsCtaClick = "on_insights_cta_click";
export const onFieldReports = "on_field_reports";
export const onFarmInsights = "on_farm_insights";
export const onDiseasePredictions = "on_disease_predictions";
export const onSyngentaTechnicalReports = "on_syngenta_technical_reports";
export const onManageFieldClick = "on_manage_field_click";
export const getMoreFieldSpecificInsights = "get_more_field_specific_insights";
export const getMoreFarmLevelInsights = "get_more_farm_level_insights";
export const viewMoreDiseaseRisks = "view_more_disease_risks";
export const contactMyRepCard = "contact_my_rep_card";
export const technicalReportView = "technical_report_view";
export const diseaseCropSelectionPredictions =
  "disease_crop_selection_predictions";
export const fieldSelectionDssat = "field_selection_dssat";
export const bannerCategoryDetails = "banner_category_details";
export const heroBannerClick = "hero_banner_click";
export const userSignupSuccess = "user_sign_up_success";
export const userSignupFailure = "user_sign_up_failure";
export const userSigninSuccess = "user_sign_in_success";
export const userSigninFailure = "user_sign_in_failure";
export const coverScreenCropGrown = "cover_screen_crop_grown";
export const coverScreenFarmSize = "cover_screen_farm_size";
export const apiServiceFailure = "api_service_failure";
export const userDefaultLocation = "user_default_location";
export const newOffersClick = "new_offers_click";
export const commitedOffersClick = "commited_offers_click";
export const claimOffersClick = "claim_offers_click";
export const recommendationClick = "on_recommendation_click";
export const templateConfigCta = "template_config_cta";
export const SIGN_UP_REQUEST_TYPE = "signup_request";
export const SIGN_UP_REQUEST_ROLE = "OTHER";
