import { type ReactNode, useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AntdConfigProvider,
  Layout,
  Sidebar,
} from "syngenta-digital-cropwise-react-ui-kit";

import { type RoutesDto } from "../../../core/types/routes.dto";
import type { ApplicationState } from "../../../core/redux/reducers";
import PackageJson from "../../../../package.json";
import { GlobalHeader } from "../header";
import SidebarMenu from "../sidebar";
import CollapseSidebar from "../sidebar/collapseSidebar";
import GlobalFooter from "../footer";
import AppLoader from "../../components/AppLoader/AppLoader";

import triggerCloseIcon from "../../../assets/icons/triggerCloseIcon.svg";
import triggerExpandIcon from "../../../assets/icons/triggerExpandIcon.svg";

interface LayoutWithMenuProps {
  children: ReactNode;
  routesWithoutMenu: RoutesDto[];
}

export const LayoutWithMenu = ({
  children,
  routesWithoutMenu,
}: LayoutWithMenuProps): JSX.Element => {
  const userProfileDetails = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  const location = useLocation();
  const [collapsedMenu, setCollapsedMenu] = useState(false);

  const breakpoint = 1200;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width < breakpoint) {
      setCollapsedMenu(true);
    } else {
      setCollapsedMenu(false);
    }
  }, [width]);
  const isLayoutWithoutMenu = useCallback(() => {
    const hideMenu: RoutesDto | undefined = routesWithoutMenu.find(
      (route) => route.path === location?.pathname
    );
    if (hideMenu) {
      return {
        hideTopbar: hideMenu.layoutWithoutHeader,
        hideSidebar: hideMenu.layoutWithoutSideMenu,
        hideFooter: hideMenu.layoutWithoutFooter,
      };
    } else {
      return {
        hideTopbar: false,
        hideSidebar: false,
        hideFooter: false,
      };
    }
  }, [routesWithoutMenu, location?.pathname]);

  if (userProfileDetails?.loading) return <AppLoader />;

  return (
    <AntdConfigProvider>
      <Layout className="content-container">
        {!isLayoutWithoutMenu().hideTopbar && <GlobalHeader />}
        <Layout>
          {!isLayoutWithoutMenu().hideSidebar && (
            <Sidebar
              expandedIcon={<SidebarMenu />}
              collapsedIcon={<CollapseSidebar />}
              collapsedMenu={collapsedMenu}
              shouldApplyPrefixClass={true}
              theme="light"
              data-cy="sidebar"
              className="mf-app-sidebar"
              versionNumber={PackageJson.version}
              triggerCloseButton={
                <img src={triggerCloseIcon} alt="triggerCloseIcon" />
              }
              triggerExpandButton={
                <img src={triggerExpandIcon} alt="triggerExpandIcon" />
              }
            />
          )}
          <Layout className="stopscrolling">{children}</Layout>
        </Layout>
      </Layout>
      {!isLayoutWithoutMenu().hideFooter && <GlobalFooter />}
    </AntdConfigProvider>
  );
};

export default LayoutWithMenu;
