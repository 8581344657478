import { Suspense, useCallback, useEffect } from "react";
import { Layout, notification } from "syngenta-digital-cropwise-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CookiesProvider } from "react-cookie";
import amplitude from "amplitude-js";
import { useTranslation } from "react-i18next";
import MainRoutes from "./routes";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import { ThemeProvider } from "./shared/layout/ThemeProvider";
import ScrollToTop from "./shared/components/scrollToTop/scrollToTop";
import AppLoader from "./shared/components/AppLoader/AppLoader";

import {
  setDefaultLocation,
  setDefaultOrgId,
  setDefaultPropertyCoordinates,
  setDefaultPropertyId,
  setIsGrowerExist,
  setLanguage,
  setProfileData,
  setProfileDataLoading,
  setRefreshUserData,
  setUserLocation,
  setValidUser,
  setLoyalityPoints,
  setUserPlans,
} from "./core/redux/reducers/accountProfileSlice";
import UserApi from "./core/api/userApi";
import LicensingApi from "./core/api/licensingApi";
import {
  deleteCookie,
  getCookie,
  isAccessTokenAvailable,
} from "./core/utils/common-function";
import {
  IdentityProviderForAmplitude,
  type ProfileInitialState,
} from "./core/types/accountProfile.dto";
import { setCurrentLanguage } from "./core/redux/reducers/homePageSlice";

import { sendAmplitudeData, setUserProperties } from "./core/utils/analytics";
import {
  SIGN_UP_REQUEST_ROLE,
  SIGN_UP_REQUEST_TYPE,
  userSigninSuccess,
  userSignupSuccess,
} from "./core/utils/analytics/constants";
import ConstantKey from "./core/locale/strings.json";

import "./index.less";

function App(): JSX.Element | null {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const userProfileDetails: ProfileInitialState = useSelector(
    (state: any) => state.accountDetials
  );

  useEffect(() => {
    if (isAccessTokenAvailable()) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (isAccessTokenAvailable() && userProfileDetails.refreshUserData) {
      getUserDetails(true);
    }
  }, [userProfileDetails.refreshUserData]);

  const failedTofetchingUser = useCallback((message: string) => {
    notification.error({
      message,
      description: "",
      placement: "topRight",
    });
    dispatch(setProfileDataLoading(false));
    navigate("/something-went-wrong", {
      state: { errorMsg: translate(ConstantKey.TECHINCAL_ISSUE_ERROR_MSG) },
    });
  }, []);

  const logAuthEvents = (response: any): void => {
    const resEmail = response?.email
      ?.trim()
      ?.toLowerCase()
      ?.includes("@syngenta");
    const userProperties = {
      user_id: response?.growerRefId,
      org_id: response?.defaultOrgId,
      property_id: response?.defaultPropertyId,
    };
    setUserProperties(userProperties);

    amplitude.getInstance().setUserId(response?.growerRefId);
    const authAction = getCookie("authAction");
    const amplitudeDetails: Record<string, unknown> = {
      country_code: response?.countryCode,
      is_syngenta_email: resEmail,
      default_workspace_id: response?.defaultWorkspaceId,
    };

    const identityType = resEmail
      ? IdentityProviderForAmplitude.SYNGENTA
      : IdentityProviderForAmplitude.LOCAL;

    if (authAction === "signin") {
      amplitudeDetails.signin_up_at = new Date().getTime();
      sendAmplitudeData(userSigninSuccess, amplitudeDetails);
    } else if (authAction === "signup") {
      amplitudeDetails.role = SIGN_UP_REQUEST_ROLE;
      amplitudeDetails.type = SIGN_UP_REQUEST_TYPE;
      amplitudeDetails.identity_provider = identityType;
      amplitudeDetails.registration_method = "web";
      amplitudeDetails.signup_up_at = new Date().getTime();
      sendAmplitudeData(userSignupSuccess, amplitudeDetails);
    }

    deleteCookie("authAction");
  };

  const updateCurrentLanguage = (response: any): void => {
    const currentLanguage = localStorage.getItem("userLanguage");

    if (currentLanguage) {
      dispatch(setCurrentLanguage(currentLanguage));
    } else {
      localStorage.setItem("userLanguage", response.locale);
      dispatch(setCurrentLanguage(response.locale));
    }
  };

  const onFetchUser = useCallback((response: any) => {
    logAuthEvents(response);
    dispatch(setProfileData(response));
    dispatch(setLanguage(response?.locale));
    dispatch(setDefaultOrgId(response?.defaultOrgId));
    if (response?.growerRefId) {
      dispatch(setIsGrowerExist(true));
    } else {
      dispatch(setIsGrowerExist(false));
    }
    if (response?.defaultLocation) {
      dispatch(setDefaultLocation(response?.defaultLocation));
      if (
        response?.defaultLocation?.city &&
        response?.defaultLocation?.countryCode
      ) {
        dispatch(
          setUserLocation(
            `${
              response?.defaultLocation?.city
            }, ${response?.defaultLocation?.countryCode.toLocaleUpperCase()}`
          )
        );
        updateCurrentLanguage(response);
      } else if (
        response.defaultLocation?.place &&
        response?.defaultLocation?.countryCode
      ) {
        dispatch(
          setUserLocation(
            `${
              response.defaultLocation?.place
            }, ${response?.defaultLocation?.countryCode.toLocaleUpperCase()}`
          )
        );
        updateCurrentLanguage(response);
      }
      if (response?.defaultPropertyId) {
        dispatch(setDefaultPropertyId(response?.defaultPropertyId));
      }
      if (
        response?.defaultLocation?.latitude &&
        response?.defaultLocation?.longitude
      ) {
        dispatch(
          setDefaultPropertyCoordinates({
            lat: response?.defaultLocation?.latitude,
            lng: response?.defaultLocation?.longitude,
          })
        );
      }
    } else {
      navigate("/drop-a-pin", { replace: true });
    }
    dispatch(setValidUser(true));
    dispatch(setProfileDataLoading(false));
    UserApi.fetchLoyaltiyPoints(response?.email)
      .then((response) => {
        if (
          !Number.isNaN(Number(response?.data?.loyalty_program_member__balance))
        ) {
          const loyaltypoin = new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 9,
          }).format(Number(response?.data?.loyalty_program_member__balance));
          dispatch(setLoyalityPoints(loyaltypoin));
        }
      })
      .catch(() => {});
  }, []);

  const getAllPlans = useCallback(() => {
    LicensingApi.getAllPlans()
      .then((response) => {
        if (response?.data?.length) {
          const allPlans = response?.data.reduce((acc: any, curr: any) => {
            const eachPlans = { [curr.planId]: curr };
            return { ...acc, ...eachPlans };
          }, {});
          dispatch(setUserPlans(allPlans));
        }
      })
      .catch(() => {});
  }, []);

  const getUserDetails = useCallback((isRefresh: boolean = false) => {
    dispatch(setProfileDataLoading(true));
    getAllPlans();
    UserApi.fetchCurrentUserInfo()
      .then((response) => {
        if (response?.status === 200) {
          onFetchUser(response?.data);
        } else {
          failedTofetchingUser("Something went wrong!");
        }
      })
      .catch((error: any) => {
        failedTofetchingUser(
          error?.cause?.data?.detail
            ? error?.cause?.data?.detail
            : "Something went wrong!"
        );
      })
      .finally(() => {
        if (isRefresh) {
          dispatch(setRefreshUserData(false));
        }
      });
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <div className="app-root">
            {/* ScrollToTop component inside App component */}
            <ScrollToTop />
            <Layout className="cw-page-container">
              {userProfileDetails.loading ? (
                <AppLoader />
              ) : (
                <Suspense fallback={<AppLoader />}>
                  <MainRoutes />
                </Suspense>
              )}
            </Layout>
          </div>
        </CookiesProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
