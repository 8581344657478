import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import {
  Button,
  ButtonType,
  Col,
  Row,
  notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../core/locale/strings.json";
import GrowerApi from "../../core/api/growerAPI";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import ForgotPasswordFooter from "./forgotPasswordFooter";
import "./forgot-password.less";

const ForgotPassword = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [inputEmail, setInputEmail] = useState("" as string);
  const [isUserValid, setIsUserValid] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(false as boolean);

  const continueSendLink = (): void => {
    setLoading(true);
    GrowerApi.checkGrowerInfo(inputEmail)
      .then((res) => {
        if (res.status === 200) {
          setIsUserValid(true);
          GrowerApi.sendForgotPasswordEmail(inputEmail)
            .then((res: any) => {
              if (res.status === 200) {
                navigate("/forgot-password-success");
              }
            })
            .catch(() => {
              notification.error({
                message: translate(ConstantKey.ERROR_SENDING_EMAIL),
                description: translate(ConstantKey.ERROR_SENDING_EMAIL_DES),
                placement: "topRight",
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        if (error?.cause?.status === 404) setIsUserValid(false);
        setLoading(false);
      });
  };

  const onChangeEmail = (event: { target: { value: string } }): void => {
    isUserValid === false && setIsUserValid(true);
    setInputEmail(event.target.value);
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={12} prefixCls="forgot-password-sec">
        <div className="forgot-password-container">
          <div className="flex-grow-0">
            <img
              className="forgot-password-field-logo flex-grow-0"
              src={synMyFieldLogo}
              alt="Syngenta Myfield Logo"
            />
          </div>
          <div className="flex-grow-0">
            <p className="forgot-password-desc">
              {translate(ConstantKey.RECOVER_PASSWORD)}
            </p>
            <p className="forgot-password-msg mf-mb-0">
              {translate(ConstantKey.FORGOT_PASSWORD_MSG)}
            </p>
          </div>
          <div className="forgot-password-form flex-grow-0">
            <div className="mf-width-100 mf-pr-10 mf-pl-10">
              <p className="forgot-password-label mf-pt-10">
                {translate(ConstantKey.YOUR_EMAIL)}
              </p>
              <Input
                type="default"
                placeholder={translate(ConstantKey.ENTER_ACCOUNT_EMAIL)}
                size="middle"
                value={inputEmail}
                className="mf-accounts-input"
                onChange={onChangeEmail}
              />
              {isUserValid === false && (
                <p className="mf-field-error-msg">
                  {translate(ConstantKey.PLEASE_ENTER_AN_ACCOUNT_EMAIL)}
                </p>
              )}
            </div>

            <Button
              loading={loading}
              type={ButtonType.primary}
              data-testid="sign-in-continue-btn"
              key="loginBtn"
              className="forgot-password-button mf-mt-10"
              onClick={continueSendLink}
              disabled={!inputEmail || isUserValid === false}
            >
              {translate(ConstantKey.SEND_LINK)}
            </Button>
          </div>
          <ForgotPasswordFooter />
        </div>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        prefixCls="forgot-password-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/forgot-password.png)`,
        }}
      ></Col>
    </Row>
  );
};

export default ForgotPassword;
